import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
export const frontmatter = {
  title: "The Comfort Economy: How Nesting is Innovating the User Experience",
  date: "2017-03-16",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20170316-0.jpg",
  path: "2017-03-16-The-Comfort-Economy",
  teaserText: "The comfort of the home serves as the most powerful antidote to the state of the world: the political noise surrounding us and the seemingly insurmountable pressures of hyper-digital and urban living. Staying in is the chill pill we all need."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="The Comfort Economy: How Nesting is Innovating the User Experience" description="The comfort of the home serves as the most powerful antidote to the state of the world: the political noise surrounding us and the seemingly insurmountable pressures of hyper-digital and urban living. Staying in is the chill pill we all need." author='Roberto Ramos' keywords={['Robots']} />
      <MDXTag name="p" components={components}>{`We all want to relax and spend more time at home. It's the natural place to be as our society shifts towards a better work/life balance. The comfort of the home serves as the most powerful antidote to the state of the world: the political noise surrounding us and the seemingly insurmountable pressures of hyper-digital and urban living. Staying in is the chill pill we all need. The home is also our charging station in between the activities and experiences we are passionate about.`}</MDXTag>
      <ImageLandscape imageSrc="20170316-1.png" caption="Blue Apron" />
      <MDXTag name="p" components={components}>{`Technology and the on-demand economy have fueled the allure of staying at home. We can pretty much order anything we want from our couch or bed, from entertainment on Netflix or Apple TV to exotic new recipes through Blue Apron and everything else from Amazon, with the latter single-handedly revolutionizing how people shop. On-demand home shopping has also reimagined how we discover things, giving consumers a platform to leisurely try new products, as the popularity of subscription services such as StitchFix and Birchbox demonstrate.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Staying in is big business, and it's serving up great incentives for innovation.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The Beauty of the Smart Home. Platforms such as Google's Nest and Amazon's Alexa are making managing household chores and key functions simpler and more fun. They are also ushering a next-gen experience that will be intuitive, fun and more personalized at all levels. Think of refrigerators that inform you what needs to be replenished and creative personalization through smart surfaces. Think of friendly and smart robots that help automate tedious functions. The house of the present/future is a blend of friendly comfort-driven functionalities and the ultimate expression of individual lifestyle and cultural interests.`}</MDXTag>
      <ImageLandscape imageSrc="20170316-2.jpg" caption="Nest" />
      <MDXTag name="p" components={components}>{`Home as Entertainment Central. Technology is also ushering a new level of media-rich experiences for the home. Besides access to boundless entertainment options, the delivery of the experience is bound to get more inspiring. Smart television sets bringing forth a lot of the interactivity we enjoy from the online world, and augmented reality stations allow us to immerse ourselves in exotic travels or fictional games. Facebook, for instance, is rumored to soon be launching a SmartTV app. As the focus on experiences continues, the kitchen's role will also be amplified. Imagine fueling greater discovery potential in the kitchen with entertainment centers that inspire you use unique dishes and ingredients that can then be easily purchased.`}</MDXTag>
      <ImageLandscape imageSrc="20170316-3.jpg" caption="Peloton Cycle" />
      <MDXTag name="p" components={components}>{`With a greater focus on wellness, think also of how the workout experience will be redefined with new home gym and experiences like Peloton which allow you to work out in the comfort of your own home while tapping into the best of a community, with access to a trainer, playlists and other enthusiasts, all on your own terms. Augmented reality simulators will also drive further interest in sports, allowing us to learn, fail and have fun, all within the comfort and privacy of the home.`}</MDXTag>
      <ImageLandscape imageSrc="20170316-4.jpg" caption="Google Offices" />
      <MDXTag name="p" components={components}>{`The New Work Environment. The home is also increasingly becoming the place where we work. Companies are tapping into telecommuting as both means of cutting costs and providing employees with a better work/life balance. This shift has brought a relaxed, creative energy to the entire work environment. This has impacted the wave of comfort and familiarity impacting visual work settings, from offices that are more relaxed (open floor plans, enticing kitchen and dining areas, play rooms) and a new workplace dress code that revolves around casualization. Comfy home-like settings are also the primary reference point for entrepreneurs and startups, with experiences ranging from startup offices in home garages to shared work spaces, with successful business models such as WeWork tapping into the power of this movement.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Comfort as a Fashion Statement. No recent development has invigorated the fashion industry as much as the push toward comfort. Athleisure, stylish fashion inspired by active sports but worn primarily outside the gym, is a powerful response to fashion's traditional comfort issues. Consumers want to carryover the same relaxed vibe they're experiencing at home to their apparel. Brands like Lululemon, Bonobos and active brands such as Nike and Adidas have moved in to deliver on this need. Even luxury brands like Gucci are serving up straight-from-your-bedroom inspirations such as their wildly successful fur trimmed loafers. This comfort style movement will continue, with greater tech-infused advancements in terms of light, weather-adaptive fabrics. Comfort truly is the new black.`}</MDXTag>
      <ImageLandscape imageSrc="20170316-5.jpg" caption="The Row" />
      <MDXTag name="p" components={components}>{`At Home Everywhere. The most powerful creative business implication around our desire for home comfort will be its experiential impact on other industries. We want to feel at home everywhere. In the retail environment, we are seeing the rise of comfortably inviting spaces like The Row's NYC townhouse, offering apparel within a homelike setting, and giving consumers a full sense of the brand's lifestyle perspective. We believe that the culinary world will continue its attraction with homestyle environments and dishes, served up in warm and comfortable settings, yet always with some form of novelty.`}</MDXTag>
      <MDXTag name="p" components={components}>{`New forms of travel will also continue to deliver this more personal experience as we've seen by the success of Airbnb, where travelers want to see the world but feel at home. We are also seeing new opportunities for real estate development as consumers crave new living environments that are designed around passion points. Think of living on a golf course, or if you're an alpha-shopper, in a new retail-centered complex. Platform in L.A. and Winwood in Miami are built as self-contained communities you might never need to leave, and the boundaries between residential and commercial real estate are becoming more fluid.`}</MDXTag>
      <MDXTag name="p" components={components}>{`As the home continues to influence a more creative economy that taps into the power of comfort and creativity, we should also explore more ways in which the home can boost our creative and innovative juices. Think of creative work or problem-solving that you can best tackle at home, away from the day-to-day minutiae at work. Think also about all the great content you can consume and create from the creative refuge of the home.`}</MDXTag>
      <MDXTag name="p" components={components}>{`From a brand perspective, think of a simple, powerful question. How would you make a consumer feel more at home across the entire brand journey? This is already a great beginning to the greatest issue impacting consumer/brand relations: lackluster customer service.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's to kicking back at home!`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    